<template>
  <v-form v-model="valid" ref="form">
    <select-praca class="mr-2"></select-praca>
    <v-menu
      class="pr-2"
      ref="retornoDate"
      lazy
      :close-on-content-click="false"
      v-model="dataRetorno"
      transition="scale-transition"
      offset-y
      full-width
      :nudge-bottom="-22"
      max-width="290px"
      :return-value.sync="dataRetornoRomaneio"
    >
      <v-text-field
        slot="activator"
        label="Data retorno"
        v-model="dataRetornoRomaneio"
        append-icon="event"
        readonly
      ></v-text-field>
      <v-date-picker v-model="dataRetornoRomaneio" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="dataRetorno = false">Cancel</v-btn>
        <v-btn flat color="primary" @click="$refs.retornoDate.save(dataRetornoRomaneio)">OK</v-btn>
      </v-date-picker>
    </v-menu>
    <div class="form-btn">
      <v-btn outline @click="gerarRelatorio" color="primary">Gerar relatório</v-btn>
      <v-btn @click="voltar" outline>Voltar</v-btn>
    </div>
    <v-snackbar :color="'pink accent-1'" v-model="snackbar" :top="true">
      {{ text }}
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../../main"
import SelectPraca from "../../praca/selectPracaPorFuncionario.vue"
import { RelatoriosSaida } from "../../../servicos/relatoriosSaida"
const servicoRelatoriosSaida = new RelatoriosSaida()
export default {
  components: {
    SelectPraca
  },
  data() {
    return {
      snackbar: false,
      text: "Gerando arquivo para impressão. Aguarde!",
      idPraca: null,
      dataRetorno: null,
      dataRetornoRomaneio: null
    }
  },
  created() {
    serverBus.$on("pracaSelected", value => {
      this.idPraca = value
    })
  },
  methods: {
    gerarRelatorio() {
      if (this.$refs.form.validate()) {
        this.snackbar = true

        servicoRelatoriosSaida.imprimir(this.dataRetornoRomaneio, this.idPraca).then(
          response => {
            var blob = new Blob([response.data], { type: "application/pdf" })
            var link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            var fileName = `${this.dataRetornoRomaneio}_Praca_${this.idPraca}.xlsx`
            link.download = fileName
            link.click()
            this.snackbar = false
          },
          err => {
            this.snackbar = true
            this.text = err.response.data[0].detail
          }
        )
      }
    },
    validarData(data) {
      var dataComBarra = data.split("/")
      if (dataComBarra.length > 1) {
        return new Date(dataComBarra[2], dataComBarra[1], dataComBarra[0])
      } else {
        return new Date(data)
      }
    },
    voltar() {
      this.$router.push({ name: "Relatorios" })
    }
  }
}
</script>
